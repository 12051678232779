.customer-360-metrics {
  overflow: scroll;
  .metric-cards-wrapper {
    display: grid;
    border: 1px solid var(--divider);
    width: 100%;
    grid-template-columns: repeat(6, 1fr);

    .metric-card {
      grid-column: span 1;
      .title-wrapper {
        display: block;
      }

      .title {
        width: 80px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }

      &.box-1 {
        box-shadow: var(--div_rb);
      }
      &.box-2 {
        box-shadow: var(--div_rb);
      }
      &.box-3 {
        box-shadow: var(--div_rb);
      }
      &.box-4 {
        box-shadow: var(--div_rb);
      }
      &.box-5 {
        box-shadow: var(--div_rb);
      }
      &.box-6 {
        box-shadow: var(--div_b);
      }
      &.box-7 {
        box-shadow: var(--div_rb);
      }
      &.box-8 {
        box-shadow: var(--div_rb);
      }
      &.box-9 {
        box-shadow: var(--div_rb);
      }
      &.box-10 {
        box-shadow: var(--div_rb);
      }
      &.box-11 {
        box-shadow: var(--div_rb);
      }
      &.box-12 {
        box-shadow: var(--div_b);
      }
      &.box-13 {
        box-shadow: var(--div_r);
      }
      &.box-14 {
        box-shadow: var(--div_r);
      }
      &.box-15 {
        box-shadow: var(--div_r);
      }
      &.box-16 {
        box-shadow: var(--div_r);
      }
      &.box-17 {
        box-shadow: var(--div_r);
      }
    }

    @media only screen and (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
      .metric-card {
        &.box-1 {
          box-shadow: var(--div_rb);
        }
        &.box-2 {
          box-shadow: var(--div_rb);
        }
        &.box-3 {
          box-shadow: var(--div_b);
        }
        &.box-4 {
          box-shadow: var(--div_rb);
        }
        &.box-5 {
          box-shadow: var(--div_rb);
        }
        &.box-6 {
          box-shadow: var(--div_b);
        }
        &.box-7 {
          box-shadow: var(--div_rb);
        }
        &.box-8 {
          box-shadow: var(--div_rb);
        }
        &.box-9 {
          box-shadow: var(--div_b);
        }
        &.box-10 {
          box-shadow: var(--div_rb);
        }
        &.box-11 {
          box-shadow: var(--div_rb);
        }
        &.box-12 {
          box-shadow: var(--div_b);
        }
        &.box-13 {
          box-shadow: var(--div_rb);
        }
        &.box-14 {
          box-shadow: var(--div_rb);
        }
        &.box-15 {
          box-shadow: var(--div_b);
        }
        &.box-16 {
          box-shadow: var(--div_r);
        }
        &.box-17 {
          box-shadow: var(--div_r);
        }
      }
    }
  }
}

.customer-360-activities {
  .activities-container {
    display: flex;
    flex-direction: column;
  }
  .ActivityItemComponent {
    padding-bottom: var(--sp_lv_4);

    .ant-timeline-item-content {
      display: flex;
      flex-direction: column;
      top: -4px;

      .timestamp,
      .channel {
        color: var(--secondary_text);
        font-size: var(--fs_sm);
        font-weight: normal;
      }

      .activity {
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: var(--fs_base);
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.customer360-purchased-category {
  .PieChartComponent .chart-content {
    width: 60% !important;
    margin: auto;
  }
}

.Customer360Header {
  .Customer360Header-segment-seeall {
    min-width: 150px;
  }
  .slick-slider {
    width: 453px !important;
    margin-left: var(--sp_lv_9) !important;
    .slick-prev {
      &:hover {
        margin-left: 22px !important;
        width: 20px !important;
        height: 20px !important;
        border-radius: 50% !important;
        background-color: rgba(0, 0, 0, 0.85) !important;
      }
      z-index: 1;
      margin-left: 22px;
      width: 40px;
      height: 100%;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) -8.04%, #ffffff 100%);
    }
    .slick-next {
      &:hover {
        margin-left: 22px !important;
        width: 20px !important;
        height: 20px !important;
        border-radius: 50% !important;
        background-color: rgba(0, 0, 0, 0.85) !important;
      }
      z-index: 1;
      margin-right: 22px;
      width: 40px;
      height: 100%;
      background: linear-gradient(270deg, #ffffff -8.04%, rgba(255, 255, 255, 0) 100%);
    }
    .slick-list {
      .slick-track {
        transform: translate3d(-114px, 0px, 0px px);
      }
    }
  }
}

.Customer360Header-segment-modal {
  max-height: 85vh;
  .ant-modal-content {
    .ant-modal-footer {
      button:first-child {
        display: none !important;
      }
    }
  }
}
